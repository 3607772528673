.richtext .ql-toolbar{
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #F2F3F4;
    z-index: 10;
}
.richtext .ql-container{
    max-height: 300px;
    overflow-y: auto;
}

.home-content-info h2 {margin-top: 26px;}
.home-content-info h3, .home-content-info h4 {margin-top: 20px;}
.home-content-info h2, .home-content-info h3, .home-content-info h4, .home-content-info p {margin-bottom: 10px;}
.home-content-info p a {color: #0B7132;}
.home-content-info ul.list_style {list-style: disc;padding-left: 40px;}
.home-content-info ul.list_style li {margin-bottom: 5px;}