/* Single blog page css */
@media (min-width: 1024px) {
    .single-blog-page h1 {
        font-size: 44px;
        line-height: normal;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h2 {
        font-size: 34px;
        line-height: normal;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h3 {
        font-size: 26px;
        line-height: normal;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h4 {
        font-size: 22px;
        line-height: normal;
        margin-bottom: 10px;
    }
}

@media (max-width: 1024px) {
    .single-blog-page h1 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h2 {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h3 {
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 10px;
    }

    .single-service-right-sidebar h4 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
    }
}