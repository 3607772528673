.footer-sec-info {
    padding-top: 50px;
}

.social-links-info .flex {
    background-color: #0b7132 
}

.green-hr-line {
    border: none;
    border-bottom: 2px solid #0B7132;
    width: 60px;
    margin: 0px;
}

.footer-sec-info p {
    font-size: 16px;
    line-height: normal;
}

.footer-copyright-info {
    border-top: 1px solid rgb(255 255 255 / 10%);
}

.footer-top-info {
    background-repeat: no-repeat;
    background-position: left bottom, right bottom;
}

@media (min-width: 768px) {
    .footer-top-info {
        background-size: 210px, 160px;
    }

    .footer-copyright-info p {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    .footer-top-info {
        background-size: 130px, 115px;
    }

    .footer-copyright-info p {
        font-size: 14px;
    }
}

#btn-back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
}