/* Discover Places Nearby Section */
.green-line-heading::after {
    content: "";
    border-bottom: 2px solid #0B7132;
    width: 82px;
    height: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.discover-nearby-box {
    margin-top: -115px;
}


/* features-section css */
.mid-img-info img {
    width: 100%;
    max-width: 350px;
    margin: 0px auto;
}

/* How We Work Section css */
.arrow-img-info {
    width: 100%;
}

.arrow-img-top-info {
    position: absolute;
    right: -115px;
    top: 3px;
}

.arrow-img-bottom-info {
    position: absolute;
    bottom: -30px;
    right: -60px;
}

/* Gallery Section */
.gallery-info {
    overflow: hidden;
    overflow-x: scroll;
    column-gap: 5px;
}

.gallery-info li img {
    object-fit: cover;
    object-position: center;
}

.gallery-info::-webkit-scrollbar {
    height: 5px;
}

.gallery-info::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

.gallery-info::-webkit-scrollbar-thumb {
    background: #0B7132;
    border-radius: 5px;
}

/* Responsive css */
@media (min-width: 1024px) {
    /* Gallery css */
    .gallery-info li {
        width: 25%;
        flex: 0 0 25%;
    }
}

@media (max-width: 1280px) {
    .arrow-img-top-info {
        right: -90px;
    }
}

@media (max-width: 1024px) {
    /* Gallery css */
    .gallery-info li {
        width: 33.333%;
        flex: 0 0 33.333%;
    }
}

@media (max-width: 768px) {
    /* Gallery css */
    .gallery-info li {
        width: 50%;
        flex: 0 0 50%;
    }
}