@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.boxAnimation {position: absolute;top: 50%;left: 50%;height: calc(100% - 44px);width: calc(100% - 34px);box-shadow: inset 0 0 0 1px #FFFFFFB2;border-top-left-radius: 30px;border-bottom-right-radius: 30px;transform: translate(-50%, -50%);z-index: 15;}
.boxAnimation:before,
.boxAnimation:after {content: '';position: absolute;box-sizing: inherit;width: 100%;height: 100%;}
.boxAnimation:before,
.boxAnimation:after {top: 0;left: 0;height: 100%;width: 100%; transform-origin: center;}
.boxAnimation:before {border-top: 2px solid #0B7132;border-bottom: 2px solid #0B7132;border-top-left-radius: 30px;border-bottom-right-radius: 30px;transform: scale3d(0, 1, 1);}
.boxAnimation:after {border-left: 2px solid #0B7132;border-right: 2px solid #0B7132;border-top-left-radius: 30px;border-bottom-right-radius: 30px;transform: scale3d(1, 0, 1);}

.team_box:hover .boxAnimation:before,
.team_box:hover .boxAnimation:after {transform: scale3d(1, 1, 1);transition: transform 0.5s;}
.our_teem figure a:hover path {fill: #fff;}
.line-clamp-2 {display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;}
.container {margin-left: auto;margin-right: auto;padding-left: 15px;padding-right: 15px;}

/* 19 Dec 2023 Common CSS */
* {font-family: 'Poppins', sans-serif;box-sizing: border-box;margin: 0px;padding: 0px;}
h1,h2,h3,h4,h5,h6 {line-height: normal;font-weight: 600;}

body, p {font-size: 16px;line-height: normal;}
.contact-txt-info {background: linear-gradient(-6deg, #0B7132 74.9%, #fff0 75.2%);}
.paralex-bg {
    background-attachment: fixed;
}
.home_charging-bg:after {content: "";position: absolute;width: 100%;height: 100%;top: 0;left: 0;transform: rotateX(180deg);clip-path: polygon(calc(100% - 28.5px) calc(100% - 0.90000000000001px), 32.2px calc(100% - 0.90000000000001px), 32.2px calc(100% - 0.90000000000001px), 26.973px calc(100% - 1.321px), 22.016px calc(100% - 2.54px), 17.395px calc(100% - 4.491px), 13.176px calc(100% - 7.108px), 9.425px calc(100% - 10.325px), 6.208px calc(100% - 14.076px), 3.591px calc(100% - 18.295px), 1.64px calc(100% - 22.916px), 0.421px calc(100% - 27.873px), 5.3248111102418E-31px calc(100% - 33.1px), 0px 87.4px, 0px 87.4px, 0.1942px 84.5609px, 0.7616px 81.8232px, 1.6794px 79.2163px, 2.9248px 76.7696px, 4.475px 74.5125px, 6.3072px 72.4744px, 8.3986px 70.6847px, 10.7264px 69.1728px, 13.2678px 67.9681px, 16px 67.1px, calc(100% - 30.8px) 0.700002px, calc(100% - 30.8px) 0.700002px, calc(100% - 26.1225px) 0.019601458px, calc(100% - 21.568px) 0.212801024px, calc(100% - 17.2235px) 1.211200686px, calc(100% - 13.176px) 2.946400432px, calc(100% - 9.5125px) 5.35000025px, calc(100% - 6.3200000000001px) 8.353600128px, calc(100% - 3.6855px) 11.888800054px, calc(100% - 1.696px) 15.887200016px, calc(100% - 0.43850000000003px) 20.280400002px, calc(100% - 5.6843418860808E-14px) 25px, calc(100% - 0px) calc(100% - 29.4px), calc(100% - 0px) calc(100% - 29.4px), calc(100% - 0.37139999999999px) calc(100% - 24.7869px), calc(100% - 1.4471999999999px) calc(100% - 20.4072px), calc(100% - 3.1698px) calc(100% - 16.3203px), calc(100% - 5.4816px) calc(100% - 12.5856px), calc(100% - 8.325px) calc(100% - 9.2625px), calc(100% - 11.6424px) calc(100% - 6.4104px), calc(100% - 15.3762px) calc(100% - 4.0887px), calc(100% - 19.4688px) calc(100% - 2.3568px), calc(100% - 23.8626px) calc(100% - 1.2741px), calc(100% - 28.5px) calc(100% - 0.90000000000001px));}

.lazy-load-image-background.blur {filter: blur(15px);}
.lazy-load-image-background.blur.lazy-load-image-loaded {filter: blur(0);transition: filter .6s;}
.lazy-load-image-background.blur > img {opacity: 0;}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {opacity: 1;transition: opacity .6s;}

/* Product Slider css */
.product-slider-info button.slick-arrow {height: 32px;}
.product-slider-info .slick-prev:before {content: "<";font-family: inherit;font-size: 34px;color: #000;}
.product-slider-info .slick-next:before{content: ">";font-family: inherit;font-size: 34px;color: #000;}
.prd-slider-thumnil-img .slick-slide img {object-fit: cover;height: 100%;width: 100%;border:3px solid #fff;}
.prd-slider-thumnil-img .slick-slide.slick-active.slick-current img{border:2px solid #0B7132;}
.prd-slider-thumnil-img .slick-slider .slick-track .slick-slide {width: 80px !important;}

@media (min-width: 1024px) {
    h1,.h1 {font-size: 44px;line-height: normal;}
    h2,.h2 {font-size: 36px;line-height: normal;}
    h3,.h3 {font-size: 30px;line-height: normal;}
    h4,.h4 {font-size: 24px;line-height: normal;}
    .paralex-bg {background-size: 200px;background-position: 80% center;}
    .prd-slider-thumnil-img .slick-slider .slick-track{display: flex;flex-direction: column;}
}
@media (min-width: 1340px) {
    .paralex-bg {background-size: 300px;background-position: 78% center;}
}
@media (min-width: 2100px) {
    .paralex-bg {background-size: 340px;background-position: 70% center;}
}
@media (max-width: 768px) {
    .paralex-bg{background-image: none !important;}
}
@media (max-width: 1024px) {
    h1,.h1 {font-size: 34px;}
    h2,.h2 {font-size: 30px;line-height: 40px;}
    h3,.h3 {font-size: 24px;line-height: 36px;}
    h4,.h4 {font-size: 20px;line-height: 32px;}
    .paralex-bg {background-size: 280px;background-position: 90% center;}
}
.animate-bounce {animation: bounce 1s infinite;}
@keyframes bounce {
    0%, 100% {transform: translateY(-25%);animation-timing-function: cubic-bezier(0.8,0,1,1);}
    50% {transform: none;animation-timing-function: cubic-bezier(0,0,0.2,1);}
}

@keyframes auto-fill {
    0% {width: calc(100% - 80px);}
    50% {width: calc(100% - 35%);}
    100% {width: calc(100% - 80px);}
}