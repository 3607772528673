.dashboard label, 
.dashboard .form-label {margin-bottom: 8px;}
.dashboard h1 {font-size: 2em;margin-bottom: 0.67em;font-weight: bold;}
.dashboard h2 {font-size: 1.5em;margin-bottom: 0.83em;}
.dashboard h3 {font-size: 1.17em;margin-bottom: 1em;font-weight: bold;}
.dashboard h4 {margin-bottom: 1.33em;font-weight: bold;}
.dashboard h5 {font-size: .83em;margin-bottom: 1.67em;font-weight: bold;}
.dashboard h6 {font-size: .67em;margin-bottom: 2.33em;font-weight: bold;} 
.dashboard header {height: 70px;background-color: black;}
.dashboard .container {display: flex;justify-content: center;align-items: center;height: 100vh;padding-top: 10%;}

.dashboard .error {width: 100%;color: rgb(200, 2, 2);font-weight: 600;font-size: 16px;}
.dashboard .form-input {height: 40px;width: 100%;padding-inline: 10px;font-size: 16px;border-radius: 3px;padding: 10px 15px;box-shadow: 0 0 2px #959595;}
.dashboard .form-input:focus {outline: none;box-shadow: 0 0 4px #0B7132;}
.dashboard .form-label {font-size: 16px;font-weight: 600;display: flex;align-items: center;}
.dashboard .form-action {background-color: #0B7132;color: white;margin-top: 10px;height: 50px;font-size: 18px;}

/* dashboard css */
.dashboard {display: flex;}
.dashboard_left {background: #F1F8E8;box-shadow: 0 0 10px #898989;position: fixed;width: 200px;overflow-x: hidden;transition: 0.5s;}
.dashboard_left_content {width: 200px;}
.dashboard_left.is_active {width: 80px;}
.dashboard .header {width: calc(100% - 200px);border-bottom: 1px solid rgba(0, 0, 0, 0.1);}
.dashboard .is_active .header {width: calc(100% - 80px);}
.dashboard_right {width: 100%;margin-left: 200px;transition: 0.5s;}
.dashboard_right.is_active {margin-left: 80px;}
.dashboard .logo {background: #fff;position: fixed;width: 200px;top: 0;padding: 0 30px;height: 70px;display: flex;line-height: 70px;display: flex;align-items: center;justify-content: center;transition: .5s;}
.dashboard .is_active .logo {padding: 0 15px;justify-content: flex-start;width: 80px;}
.dashboard .header_toogle {cursor: pointer;}
.dashboard .menu_line1,
.dashboard .menu_line2,
.dashboard .menu_line3 {width: 30px;height: 2px;background-color: #0B7132;margin: 6px 0;transition: 0.4s;}
.dashboard .is_active .menu_line1 {transform: translate(0, 5px) rotate(-45deg);}
.dashboard .is_active .menu_line2 {opacity: 0;}
.dashboard .is_active .menu_line3 {transform: translate(0, -11px) rotate(45deg);}
.dashboard .is_active .large_logo {display: none;}
.dashboard .small_logo {display: none;}
.dashboard .is_active .small_logo {display: block;}
.dashboard_bar {padding: 30px;display: flex;flex-direction: column;justify-content: space-between;height: calc(100vh - 70px);margin-top: 70px;overflow-y: auto;}
.dashboard .nav li {margin-bottom: 12px;}
.dashboard .dashboard_left a {font-size: 18px;color: #0B7132;}
.dashboard .nav .linkText,
.dashboard .log-out .linkText {padding-left: 5px;cursor: pointer;transition: .5s;}
.dashboard .is_active .linkText {opacity: 0;}
.dashboard .dashboard_left a:hover {color: #0B7132;}
.dashboard .header {height: 70px;background-color: #F1F8E8;padding: 0 30px;display: flex;align-items: center;justify-content: space-between;transition: .5s;
  position: fixed;z-index: 1111;top: 0;right: 0;}
.dashboard .is_active header {margin-left: 80px;}
.dashboard .header_right {display: flex;align-items: center;}
.dashboard .header_links .links {margin-right: 30px;font-size: 18px;}
.dashboard .dashboard_info {background: #e0dfee;padding: 20px;color: #0B7132;box-shadow: 0 0 2px #0B7132;}
.dashboard .profile_img {width: 30px; height: 30px; border-radius: 50%; overflow: hidden; display: inline-block;}
.dashboard .user_profile #navbarDropdown {display: flex;align-items: center;}
.dashboard .menu_opne {display: block;}
.dashboard .is_active .menu_opne {display: none;}
.dashboard .menu_close {display: none;}
.dashboard .is_active .menu_close {display: block;}
.dashboard .css-sghohy-MuiButtonBase-root-MuiButton-root {background-color: #0B7132 !important;}
.dashboard .products_info {background: #e0dfee;padding: 20px;color: #0B7132;box-shadow: 0 0 2px #0B7132;}
.dashboard .products_info button {width: 100%;margin-top: 20px;}
.dashboard .products_info p {margin-bottom: 10px;}
.dashboard .ragister_logo {background: #0B7132;}
.dashboard .register_form {background: #cbd5e1;width: 100%;min-height: 100vh;
  /* background-image: url("./assets/images/register_bg.jpg"); */
  background-attachment: fixed;position: relative;
  background-position: center;background-size: cover;display: flex;align-items: center;}
.dashboard .register_form:before {content: "";position: absolute;width: 100%;height: 100%;top: 0;left: 0;background: rgba(32, 30, 30, 0.9);}
.dashboard .text_link {color: #0B7132;}
.dashboard .form_design {max-width: 630px;padding: 40px;margin: 40px auto;border-radius: 10px;}
.dashboard .text_link:hover {color: #0B7132;}
.dashboard .register_form .inpit_row {margin-bottom: 15px;}
.dashboard .register_form h1 {margin: auto;margin-bottom: 20px;color: #0B7132;}
.dashboard .register_form .Welcome_heading {margin-bottom: 5px;}

@media screen and (max-width: 992px) {
  .dashboard h1 {font-size: 1.8em;}
  .dashboard .form_design {padding: 30px;}
}
@media screen and (max-width: 767px) {
  .dashboard h1 {font-size: 1.6em;margin-bottom: 0.6em;}
  .dashboard .form_design {padding: 25px;margin: 40px 15px;}
  .dashboard .register_form .inpit_row {margin-bottom: 15px;}
  .dashboard .register_form .inpit_row img {width: 25px;}
}
